export enum CHOIX {
  OUI = 'oui',
  NON = 'non',
}

export const specificPageNames = {
  demande: 'regroupement_familial',
  mentions: 'mentions_legales',
  plan: 'plan_site',
  mes: 'documents',
};
