import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

type ObjError = {
  data: { message: string; error: string; status: number };
  status: number;
};
type ObjError3 = {
  data: { title: string; type: string; status: number };
  status: number;
};

export function isErrorWithMessage2(error: unknown): error is ObjError {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof (error as any).data === 'object'
  );
}

export function isErrorWithMessage3(error: unknown): error is ObjError3 {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof (error as any).data === 'object' &&
    'title' in (error as any).data
  );
}

export const getErrorMessage = (err: unknown): string => {
  if (isErrorWithMessage3(err)) {
    console.log('isErrorWithMessage3', err);
    return err.data.title;
  } else if (isErrorWithMessage2(err)) {
    console.log('isErrorWithMessage2', err);
    return err.data.message;
  } else if (isFetchBaseQueryError(err)) {
    console.log('isFetchBaseQueryError', err);
    return 'error' in err ? err.error : JSON.stringify(err.data);
  } else if (isErrorWithMessage(err)) {
    console.log('isFetchBaseQueryError', err);
    return err.message;
  } else {
    console.log('Erreur inconnue', err);
  }
  return 'Erreur inconnue';
};
