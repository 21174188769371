import { CardProps } from '@codegouvfr/react-dsfr/Card';
import { FC } from 'react';

type ClickableCardProps = Omit<CardProps, 'linkProps'> & {
  badgeText?: string;
  imageMobileUrl?: string;
  onClick?: () => void;
  href: string;
};

const ClickableCard: FC<ClickableCardProps> = ({
  title,
  desc,
  imageUrl,
  imageMobileUrl,
  imageAlt,
  classes,
  detail,
  href,
  badgeText,
  onClick,
}) => {
  if (!imageMobileUrl) {
    imageMobileUrl = imageUrl;
  }
  return (
    <div className={`container bloc-demande-rf ${classes?.root}`}>
      <div className="fr-card fr-enlarge-link fr-card--horizontal fr-card--horizontal-tier">
        <div className="fr-card__body">
          <div className="fr-card__content">
            <h3 className="fr-card__title">
              <a href={href} onClick={onClick}>
                {title}
              </a>
            </h3>
            <p className="fr-card__desc">{desc}</p>
            <div className="fr-card__start">
              <p className={`fr-card__detail ${classes?.detail}`}>{detail}</p>
            </div>
            <div className="fr-card__end"></div>
          </div>
        </div>
        <div className="fr-card__header">
          {imageUrl && imageMobileUrl && (
            <>
              <div className="fr-card__img hide-on-mobile">
                <img className="fr-responsive-img" src={imageUrl} alt={imageAlt || ''} />
              </div>
              <div className="fr-card__img hide-on-desktop">
                <img className="fr-responsive-img" src={imageMobileUrl} alt={imageAlt || ''} />
              </div>
            </>
          )}
          <ul className="fr-badges-group">
            <li>
              <p className="fr-badge fr-badge--info">{badgeText}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClickableCard;
