/* eslint-disable complexity */
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTus } from 'use-tus';
import { Button } from '@codegouvfr/react-dsfr/Button';
import { Upload } from '@codegouvfr/react-dsfr/Upload';

import { API_BASE_URL } from 'constants/api';
import { MAX_FILE_SIZE } from 'constants/upload';
import { STATUT_DOCUMENT } from 'constants/documents/regroupementFamilial';
import { convertMimeTypeToExtension } from 'helpers/api/upload';
import { DocumentReformat } from 'models/types/api/rf';
import { UploaderProps } from 'models/types/app/uploadFiles';
import { useDelDocumentMutation } from 'redux/rtk/api/apiDocuments';
import { createError } from 'redux/forms';
import { getUser } from 'services/authentication';

import ProgressBar from '../ProgressBar/ProgressBar';
import { getUploadOptions } from './uploader.utils';
import './Uploader.scss';

const Uploader: FC<UploaderProps> = ({
  name,
  categorie,
  demandeId,
  formInfos,
  formControl,
  isNew = 'non',
  item,
  index,
  onSuccess,
  defaultValue,
  fieldArray,
  resource,
  resourceId,
  required,
  stateRelatedMessage,
  cmsContentUtilitaire,
  errorCms,
}) => {
  const [percentage, setPercentage] = useState<number>(0);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isProgess, setIsProgess] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [fileName, setFileName] = useState('');
  const [deleteDocument] = useDelDocumentMutation();
  const dispatch = useDispatch();
  const watchValue = formControl.watch(`${name}.${index}`);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (item?.document?.statut === STATUT_DOCUMENT.INFECTED) {
      setErrorMsg(errorCms.texte_fichier_infecte);
    }
  }, [item?.document?.statut]);

  useEffect(() => {
    if (
      watchValue &&
      watchValue.document !== undefined &&
      watchValue.document.nomFichierPublic !== null &&
      (fileName === '' || fileName !== watchValue.document.nomFichierPublic)
    ) {
      setFileName(watchValue.document.nomFichierPublic);
    }
  }, [watchValue, setFileName, fileName]);

  const user = getUser();
  const token = user?.access_token;

  const handleUploadSuccess = (id: string, originalFileName: string) => {
    const newDocument: DocumentReformat = {
      id: null,
      document: {
        id: id,
        categorie: categorie,
        statut: null,
        nomFichier: null,
        nomFichierPublic: originalFileName,
        idRfng: null,
        options: null,
      },
    };
    fieldArray.update(index, newDocument);
    if (newDocument.document) {
      onSuccess(newDocument.document);
    }
  };

  const uploadOptions = getUploadOptions(
    setPercentage,
    setIsProgess,
    setErrorMsg,
    handleUploadSuccess,
  );

  const { setUpload } = useTus({
    autoAbort: true,
    autoStart: true,
    uploadOptions,
  });

  const onFileDelete = async (item: DocumentReformat) => {
    try {
      if (formInfos.deleteFunction && item.document?.id) {
        dispatch(
          formInfos.deleteFunction({
            id: formInfos.id,
            documentId: item.document?.id,
          }),
        );
        fieldArray.remove(index);
        deleteDocument({ demandeId, documentId: item.document?.id as string });
      } else {
        throw new Error('deleteFunction not found');
      }
    } catch (e: any) {
      console.log(e.response);
    }
  };

  const handleSetUpload = useCallback(
    (uuid: string | null, e: ChangeEvent<HTMLInputElement>) => {
      const limitFileSize = MAX_FILE_SIZE;
      const fileList = e.target.files;
      if (!fileList) return;

      const file = e.target.files?.item(0);

      if (!token) return;
      if (!file) return;

      const fileSizeKiloBytes = file.size / 1024;
      if (fileSizeKiloBytes > limitFileSize) {
        setErrorMsg(errorCms.texte_erreur_fichier_trop_gros);
        return;
      }

      const filenameUuid = uuidv4();
      const fileClone = new File(
        [file],
        `${filenameUuid}.${convertMimeTypeToExtension(file.type)}`,
        {
          type: file.type,
          lastModified: file.lastModified,
        },
      );

      setIsProgess(true);
      setErrorMsg(null);
      setUpload(file, {
        endpoint: `${API_BASE_URL}/api/private/server/tus/`,
        removeFingerprintOnSuccess: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        metadata: {
          filename: fileClone.name,
          filetype: fileClone.type,
          originalFilename: file.name,
          categorie: categorie,
          demandeId: demandeId,
          uuid: uuid ? uuid : '',
          isNew: isNew,
          demandeConjointId: resource === 'conjoint' && resourceId ? resourceId : '',
          demandeEnfantId: resource === 'enfant' && resourceId ? resourceId : '',
          demandeLogementId: resource === 'logement' && resourceId ? resourceId : '',
          demandeResourceId: resource === 'ressource' && resourceId ? resourceId : '',
          demandeUnionId: resource === 'union' && resourceId ? resourceId : '',
        },
      });
    },
    [setUpload],
  );

  const getUploadStatusIsCorrect = (status: string) => {
    return (
      status === STATUT_DOCUMENT.UPLOADED ||
      status === STATUT_DOCUMENT.FAILED_SCAN ||
      status === STATUT_DOCUMENT.SCANNED ||
      status === STATUT_DOCUMENT.FAILED_MOVE_TO_BUCKET ||
      status === STATUT_DOCUMENT.PENDING ||
      status === STATUT_DOCUMENT.INFECTED
    );
  };

  useEffect(() => {
    errorMsg && dispatch(createError(errorMsg));
  }, [errorMsg]);

  return (
    <div className="uploader">
      <div className="uploader-container">
        {(!item || (item && item.document?.statut !== STATUT_DOCUMENT.INFECTED)) && (
          <Upload
            label=""
            hint=""
            state="default"
            stateRelatedMessage={errorMsg ? errorMsg : stateRelatedMessage}
            nativeInputProps={{
              onChange: event => handleSetUpload(item?.document?.id as string, event),
              onFocus: () => {
                setFocused(true);
              },
              onBlur: () => {
                setFocused(false);
              },
              required: required,
              id: `${name}.${index}.files-control`,
            }}
            className="upload-file-input"
          />
        )}

        <Controller
          name={`${name}.${index}`}
          control={formControl.control}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, name } }) => {
            return (
              <input type="hidden" name={name} onChange={onChange} onBlur={onBlur} value={value} />
            );
          }}
        />
        <label
          className={`custom-label fr-label ${focused ? 'focused' : ''}`}
          htmlFor={`${name}.${index}.files-control`}
        >
          {cmsContentUtilitaire?.data?.attributes?.composant_fichier_choisir_un_fichier}
        </label>
        <div className="upload-file-name">
          <span className="fr-hint-text">
            {item?.document?.id &&
            item?.document?.statut &&
            getUploadStatusIsCorrect(item?.document?.statut) ? (
              <>
                {item.document?.nomFichierPublic
                  ? item.document?.nomFichierPublic
                  : item.document?.nomFichier}
              </>
            ) : item?.document?.id ? (
              <>{'... Chargement'}</>
            ) : (
              <>
                {
                  cmsContentUtilitaire?.data?.attributes
                    ?.composant_fichier_aucun_fichier_selectionne
                }
              </>
            )}
          </span>
        </div>

        {item && item.id && item.document?.id && (
          <Button
            className="fr-btn fr-icon-delete-line fr-btn--tertiary bin-button"
            data-fr-js-button-actionee="true"
            priority="tertiary no outline"
            children=""
            onClick={() => {
              setIsDeleted(true);
              onFileDelete(item);
            }}
            disabled={isDeleted}
          ></Button>
        )}
        {errorMsg && (
          <div
            style={{
              flex: '1 1 100%',
            }}
          >
            <span className="fr-error-text">{errorMsg} </span>
          </div>
        )}
      </div>
      {isProgess && <ProgressBar progress={percentage} />}
    </div>
  );
};

export default Uploader;
