import React, { FC } from 'react';
import { CallOut } from '@codegouvfr/react-dsfr/CallOut';

import { RFClefStatut, RFDemande, RFStatut } from 'models/types/api/rf';

import DocumentsManquants from './DocumentsManquants';
import DossierCloture from './DossierCloture';
import { shouldDisplayDocumentsManquants } from '../helpers/utils';

type Props = {
  demande: RFDemande;
  traductionRFCms: any;
  suiviRFCms: any;
};
const MiseEnAvant: FC<Props> = ({ demande, traductionRFCms, suiviRFCms }) => {
  if (demande.clefStatut === RFClefStatut.MOTIF && demande.statut === RFStatut.DOSSIER_CLOTURE) {
    return <DossierCloture suiviRFCms={suiviRFCms} />;
  }
  const cmsTraductionByClefStatut = traductionRFCms.clef_statuts[demande.clefStatut];
  return (
    <CallOut
      iconId="fr-icon-info-line"
      title={cmsTraductionByClefStatut.explication_titre}
      className="fr-my-md-5w fr-mt-3w fr-mb-5w"
      colorVariant={
        shouldDisplayDocumentsManquants(demande) ||
        (demande.clefStatut === RFClefStatut.DECISION_FAVORABLE_RELANCE_PAIEMENT &&
          demande.statut === RFStatut.FAVORABLE_RELANCE_PAIEMENT) ||
        (demande.clefStatut === RFClefStatut.DECISION_FAVORABLE_ATTENTE_PAIEMENT &&
          demande.statut === RFStatut.FAVORABLE_ATTENTE_PAIEMENT)
          ? 'orange-terre-battue'
          : undefined
      }
    >
      {cmsTraductionByClefStatut.explication_description}
      {cmsTraductionByClefStatut.en_attente_details && (
        <>
          {cmsTraductionByClefStatut.explication_description.length >= 2 && (
            <>
              <br />
              <br />
            </>
          )}
          {cmsTraductionByClefStatut.en_attente_details}
          {shouldDisplayDocumentsManquants(demande) && (
            <DocumentsManquants
              headingText={suiviRFCms.liste_des_documents_manquants}
              documentList={demande.documentsManquant}
            />
          )}
        </>
      )}
    </CallOut>
  );
};

export default MiseEnAvant;
