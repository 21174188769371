import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TilesContainer from 'components/Molecules/TilesContainer/TilesContainer';
import Banner from 'components/Molecules/Banner/Banner';
import ThreeCardContainer from 'components/Molecules/ThreeCardContainer/ThreeCardContainer';
import TwoCardContainer from 'components/Molecules/TwoCardContainer/TwoCardContainer';
import FourCardContainer from 'components/Molecules/FourCardContainer/FourCardContainer';
import { useGetAccueilCmsPageQuery } from 'redux/rtk/cms/cmsHome';
import { setAccueil, setArticles, setLayout, setProcedures, setServices } from 'redux/pages';
import {
  getAccueilPage,
  getArticles,
  getLayoutBlocs,
  getOnlineServices,
  getProcedures,
} from 'redux/pages/selectors';
import { ARTICLES, PROCEDURES, SERVICES_EN_LIGNE } from 'routes/path';
import Loader from 'components/Atoms/Loader/Loader';
import { getCurrentLocale } from 'redux/locale/selectors';
import { getNewsContent } from 'helpers/cms/getNewsContent';
import { accueilQuery } from './Home.query';
import {
  useGetLayoutCmsPageQuery,
  useGetOnlineServicesCmsCollectionQuery,
} from 'redux/rtk/cms/cmsCommon';
import {
  generateAccueilEtIntegrationSection,
  generateSection,
  generateServicesSection,
} from 'helpers/app/pagesHelper';
import { getProceduresContent } from 'helpers/cms/getProceduresContent';

import './Home.scss';

// eslint-disable-next-line complexity
const Home = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const { articlesCms, articlesCmsError, isArticlesCmsLoading } = getNewsContent(lang);
  const { proceduresCms, proceduresCmsError, isProceduresCmsLoading } = getProceduresContent(lang);

  const {
    data: accueilCms,
    error: accueilCmsError,
    isLoading: isAccueilCmsLoading,
  } = useGetAccueilCmsPageQuery({
    qs: accueilQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: onlineServicesCms,
    error: onlineServicesCmsError,
    isLoading: isOnlineServicesCmsLoading,
  } = useGetOnlineServicesCmsCollectionQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: layoutCms,
    error: layoutCmsError,
    isLoading: isLayoutCmsLoading,
  } = useGetLayoutCmsPageQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const dispatch = useDispatch();
  const articlesStore = useSelector(getArticles);
  const proceduresStore = useSelector(getProcedures);
  const onlineServicesStore = useSelector(getOnlineServices);
  const accueilPage = useSelector(getAccueilPage);
  const layoutBlocs = useSelector(getLayoutBlocs);

  useEffect(() => {
    if (accueilCms) {
      dispatch(setAccueil(accueilCms));
    }
  }, [accueilCms, dispatch]);

  useEffect(() => {
    if (
      proceduresCms.data &&
      proceduresCms.data.length > 1 &&
      (proceduresStore?.length ?? 0) <= 1
    ) {
      dispatch(setProcedures(proceduresCms));
    }
  }, [proceduresCms, dispatch, proceduresStore]);

  useEffect(() => {
    if (articlesCms.data && articlesCms.data.length > 1 && (articlesStore?.length ?? 0) <= 1) {
      dispatch(setArticles(articlesCms.data));
    }
  }, [articlesCms, dispatch, articlesStore]);

  useEffect(() => {
    if (onlineServicesCms) {
      dispatch(setServices(onlineServicesCms.data));
    }
  }, [onlineServicesCms, dispatch]);

  useEffect(() => {
    if (layoutCms) {
      dispatch(setLayout(layoutCms));
    }
  }, [layoutCms, dispatch]);

  if (
    isAccueilCmsLoading ||
    isProceduresCmsLoading ||
    isArticlesCmsLoading ||
    isOnlineServicesCmsLoading ||
    isLayoutCmsLoading
  ) {
    return <Loader />;
  }

  if (
    accueilCmsError ||
    proceduresCmsError ||
    articlesCmsError ||
    onlineServicesCmsError ||
    layoutCmsError
  ) {
    return <div>Redirect page error</div>;
  }

  if (!accueilCms || !layoutCms) {
    return null;
  }

  return (
    <main>
      {accueilPage.banner && (
        <Banner
          title={accueilPage.banner?.titre}
          description={accueilPage.banner?.sous_titre}
          actionButton={{
            href: 'creation-compte',
            title: accueilPage?.banner?.texte_button,
          }}
        />
      )}
      {accueilPage.services && (
        <TilesContainer
          className="portail"
          title={accueilPage.services.titre}
          tiles={generateServicesSection(accueilPage)}
        />
      )}
      {layoutBlocs.services && onlineServicesStore && (
        <ThreeCardContainer
          className="services"
          title={layoutBlocs.services.titre}
          description={layoutBlocs.services.sous_titre}
          link={{
            href: SERVICES_EN_LIGNE,
            title: layoutBlocs.services.texte_lien,
          }}
          cards={onlineServicesStore.slice(0, 3)}
        />
      )}
      {layoutBlocs.procedures && proceduresStore && (
        <ThreeCardContainer
          className="demarche"
          title={layoutBlocs.procedures.titre}
          description={layoutBlocs.procedures.sous_titre}
          link={{
            href: PROCEDURES,
            title: layoutBlocs.procedures.texte_lien,
          }}
          cards={generateSection(proceduresStore.slice(0, 3))}
        />
      )}
      {accueilPage.integration && (
        <TwoCardContainer
          title={accueilPage.integration.titre}
          horizontal={true}
          className="integration"
          cards={generateAccueilEtIntegrationSection(accueilPage)}
        />
      )}
      {layoutBlocs.articles && articlesStore && (
        <FourCardContainer
          className="news"
          title={layoutBlocs.articles.titre}
          link={{
            href: ARTICLES,
            title: layoutBlocs.articles.bouton_voir_plus,
          }}
          cards={generateSection(articlesStore.slice(0, 4))}
        />
      )}
    </main>
  );
};

export default Home;
