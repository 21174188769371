/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import {
  ConsentBannerAndConsentManagement,
  FooterConsentManagementItem,
} from 'components/Molecules/ConsentManagement/ConsentManagement';
import { ConsentWrapper } from 'components/Molecules/ConsentManagement/ConsentWrapper';
import { ConsentTranslation } from 'components/Molecules/ConsentManagement/ConsentTranslation';
import Header from 'components/Molecules/CustomHeader/CustomHeader';
import Footer from 'components/Molecules/CustomFooter/CustomFooter';
import Socialbanner from 'components/Molecules/SocialBanner/SocialBanner';
import Loader from 'components/Atoms/Loader/Loader';
import Toast from 'components/Molecules/Toast/Toast';
import {
  generateFooterData,
  generateHeaderData,
  generateSocialBannerData,
} from 'helpers/app/layoutHelper';
import { cookieQuery } from 'pages/Home/Home.query';
import DemandeRfSubmittedModal from 'pages/Dashboard/components/DemandeRfSubmittedModal';
import { getFooter, getHeader, getMenu } from 'redux/pages/selectors';
import { isKeycloakAlive } from 'redux/keycloak/selectors';
import { useGetCookieCmsPageQuery } from 'redux/rtk/cms/cmsCookie';
import { setCookiePage } from 'redux/pages/slice';
import { getCurrentLocale } from 'redux/locale/selectors';

import 'App.scss';

const MainLayout: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const menuData = useSelector(getMenu);
  const footerData = useSelector(getFooter);
  const cmsHeader = useSelector(getHeader);
  const dispatch = useDispatch();
  const auth = useAuth();
  const keycloakAlive = useSelector(isKeycloakAlive);

  const { data: cms } = cookieQuery
    ? useGetCookieCmsPageQuery({
        qs: cookieQuery,
        locale: lang !== '' ? lang : 'fr',
      })
    : { data: null };

  useEffect(() => {
    if (cms) {
      dispatch(setCookiePage(cms.data));
    }
  }, [cms, dispatch]);

  if (auth.isLoading) {
    return <Loader fullScreen={'fullscreen'} />;
  }

  if (auth.error) {
    console.error(auth.error);
    //return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <>
      <ConsentBannerAndConsentManagement />
      <ConsentWrapper />
      <ConsentTranslation lang={lang} />
      <div className="App">
        <DemandeRfSubmittedModal lang={lang} />
        <Toast />
        {menuData && (
          <Header
            {...generateHeaderData(
              {
                path: window.location.pathname,
                currentLanguage: lang,
              },
              {
                auth: auth,
                keycloakAlive: keycloakAlive,
              },
              menuData ? menuData : undefined,
              cmsHeader ? cmsHeader : undefined,
            )}
          />
        )}
        <Outlet
          context={{
            lang: lang,
          }}
        />
        {footerData && (
          <>
            <Socialbanner {...generateSocialBannerData(footerData)} />
            <Footer
              {...generateFooterData(
                {
                  currentLanguage:
                    window.location.pathname.substring(1).split('/')[0].length === 2
                      ? window.location.pathname.substring(1).split('/')[0]
                      : '',
                },
                footerData,
              )}
              bottomItems={[<FooterConsentManagementItem key="alone" />]}
            />
          </>
        )}
      </div>
    </>
  );
};

export default MainLayout;
