/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';

import { ProceduresCmsComponent } from 'models/types/cms/dashboard/type';
import { ProcedureDemandeType } from 'models/types/api/procedures';
import { DemandeAsile } from 'models/types/api/demandeAsile';
import {
  getListDemandeRFEnCours,
  getListRFDemandeEnCoursSummary,
  getListRFDemandeTermineeSummary,
} from 'redux/demandeRf/selectors';
import { getDemandeAsileEnCours, getDemandeAsileTerminee } from 'redux/asile/selectors';
import { isFeatureActive } from 'services/featureToggling/isFeatureActive';
import { Features } from 'services/featureToggling/features';

import SimpleCard from '../SimpleCard/SimpleCard';
import ProcedureList from './components/ProcedureList/ProcedureList';

interface Props {
  cmsComponent: ProceduresCmsComponent;
  cmsRFTraductionAPI: any;
}

const UserProcedures: React.FC<Props> = ({ cmsComponent, cmsRFTraductionAPI }) => {
  const derniereDemandeAsileEnCoursList = useSelector(getDemandeAsileEnCours).filter(
    (procedure: DemandeAsile): procedure is DemandeAsile =>
      procedure.type === ProcedureDemandeType.ASILE &&
      procedure.dateSignatureOPC !== null &&
      procedure.path !== '',
  );
  const derniereDemandeAsileTermineeList = useSelector(getDemandeAsileTerminee);
  const procedureListEnCoursRF = useSelector(getListRFDemandeEnCoursSummary);

  const procedureListTermineesRF = useSelector(getListRFDemandeTermineeSummary);
  // const procedureListEnCoursRRIRetour = useSelector(getDemandeRetourEnCours);
  // const procedureListTermineeRRIRetour = useSelector(getDemandeRetourTerminee);
  // const procedureListEnCoursRRIReinsertion = useSelector(getDemandeReinsertionEnCours);
  // const procedureListTermineeRRIReinsertion = useSelector(getDemandeReinsertionTerminee);

  const procedureListDemandeRFEnCours = isFeatureActive(Features.REGROUPEMENT_FAMILIAL)
    ? // eslint-disable-next-line
      useSelector(getListDemandeRFEnCours)
    : [];

  const procedureListEnCours = [
    ...derniereDemandeAsileEnCoursList,
    ...procedureListDemandeRFEnCours,
    ...procedureListEnCoursRF,
    // ...procedureListEnCoursRRIRetour,
    // ...procedureListEnCoursRRIReinsertion,
  ];

  const procedureListTerminees = [
    ...derniereDemandeAsileTermineeList,
    ...procedureListTermineesRF,
    // ...procedureListTermineeRRIRetour,
    // ...procedureListTermineeRRIReinsertion,
  ];

  return (
    <div>
      {procedureListEnCours.length === 0 && procedureListTerminees.length === 0 ? (
        <div>
          <SimpleCard
            desc={cmsComponent.texte_aucune_procedure}
            imageUrl="/img/dashboard/no-more-notifications.png"
          />
        </div>
      ) : (
        <>
          {procedureListEnCours.length !== 0 && (
            <>
              <h3>{cmsComponent.titre_en_cours}</h3>
              <div>
                <ProcedureList
                  cmsComponent={cmsComponent}
                  cmsRFTraductionAPI={cmsRFTraductionAPI}
                  tableauDeBordProcedureList={procedureListEnCours}
                />
              </div>
            </>
          )}
          {procedureListTerminees.length !== 0 && (
            <>
              <h3 className="fr-mt-6v">{cmsComponent.titre_termines}</h3>
              <div>
                <ProcedureList
                  cmsComponent={cmsComponent}
                  cmsRFTraductionAPI={cmsRFTraductionAPI}
                  tableauDeBordProcedureList={procedureListTerminees}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserProcedures;
