/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { compareDesc } from 'date-fns';
import { useForm } from 'react-hook-form';

import { getListDemandeRFEnCours, getListDemandeRFPending } from 'redux/demandeRf/selectors';
import { isFeatureActive } from 'services/featureToggling/isFeatureActive';
import { Features } from 'services/featureToggling/features';
import { RappelListItem } from 'constants/rappels';
import ClickableCard from 'components/Molecules/ClickableCard/ClickableCard';
import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';

import RappelsCard from './RappelsCard';
import SimpleCard from '../SimpleCard/SimpleCard';

interface Props {
  rappelCms: any;
  globalCms: any;
  onSubmitRfBloc: () => Promise<void>;
  rappels: RappelListItem[];
  className?: string;
  isLightMode?: boolean;
  asileRappels?: boolean;
  demandeHasLoad?: boolean;
  enableNewDemandeRf?: boolean;
}

const Rappels: React.FC<Props> = ({
  rappelCms,
  globalCms,
  rappels,
  onSubmitRfBloc,
  enableNewDemandeRf,
  demandeHasLoad,
}) => {
  const { handleSubmit } = useForm();
  const sortedRappels = rappels.sort((a, b) => compareDesc(a.date ?? 0, b.date ?? 0));
  const showRfPendingBloc = useSelector(getListDemandeRFPending).length > 0;
  const thereIsDemandeRfOnGoing = useSelector(getListDemandeRFEnCours).length > 0;

  if (!globalCms && !rappelCms) {
    return null;
  }

  const showCreateDemandeRfBloc =
    !showRfPendingBloc &&
    isFeatureActive(Features.REGROUPEMENT_FAMILIAL) &&
    enableNewDemandeRf &&
    demandeHasLoad &&
    !thereIsDemandeRfOnGoing;

  return (
    <div>
      {showRfPendingBloc && (
        <CustomAlert
          severity="info"
          title="Information"
          description={rappelCms.texte_rappel_envoi_rf_soumis}
        />
      )}

      {sortedRappels.length > 0 ? (
        <>
          {sortedRappels.map(rappel => (
            <RappelsCard key={rappel.key} rappel={rappel} rappelCms={rappelCms} />
          ))}
          {showCreateDemandeRfBloc && (
            <ClickableCard
              imageUrl="/img/dashboard/illustration-rf-desktop.svg"
              imageMobileUrl="/img/dashboard/illustration-rf-mobile.svg"
              imageAlt={globalCms.balise_alt_illustration_demarrage_procedure_rf}
              title={globalCms.titre_bloc_demarrage_regroupement_familial}
              desc={globalCms.contenu_bloc_demarrage_regroupement_familial}
              detail={globalCms.nom_bloc_demarrage_regroupement_familial}
              classes={{
                detail: 'fr-icon-arrow-right-line',
                root: 'fr-mb-8v',
              }}
              href="#"
              onClick={handleSubmit(onSubmitRfBloc)}
              badgeText={globalCms.nouveaute}
            />
          )}
        </>
      ) : showCreateDemandeRfBloc ? (
        <ClickableCard
          imageUrl="/img/dashboard/illustration-rf-desktop.svg"
          imageMobileUrl="/img/dashboard/illustration-rf-mobile.svg"
          imageAlt={globalCms.balise_alt_illustration_demarrage_procedure_rf}
          title={globalCms.titre_bloc_demarrage_regroupement_familial}
          desc={globalCms.contenu_bloc_demarrage_regroupement_familial}
          detail={globalCms.nom_bloc_demarrage_regroupement_familial}
          classes={{
            detail: 'fr-icon-arrow-right-line',
            root: 'fr-mb-8v',
          }}
          href="#"
          onClick={handleSubmit(onSubmitRfBloc)}
          badgeText={globalCms.nouveaute}
        />
      ) : (
        <SimpleCard
          desc={rappelCms.aucun_rappel}
          imageUrl="/img/dashboard/no-more-notifications.png"
        />
      )}
    </div>
  );
};

export default Rappels;
