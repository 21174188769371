/* eslint-disable complexity */
import { useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { ARTICLES } from 'routes/path';
import { globalQuery } from 'pages/DemandeRF/Queries';
import { generateSection } from 'helpers/app/pagesHelper';

import { getArticles } from 'redux/pages/selectors';
import { useGetGlobalCmsQuery } from 'redux/rtk/cms/cmsDemandeRF';
import { useGetLayoutCmsPageQuery } from 'redux/rtk/cms/cmsCommon';

import AppImage from 'components/Atoms/Image/Image';
import Loader from 'components/Atoms/Loader/Loader';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import ClickableCard from 'components/Molecules/ClickableCard/ClickableCard';
import FourCardContainer from 'components/Molecules/FourCardContainer/FourCardContainer';
import CmsContentGenerator from 'components/Atoms/CmsContentGenerator/CmsContentGenerator';

import './CmsPage.scss';

type CmsPageParams = {
  lien: string;
};

const CmsPage: FC<any> = ({ type, store, lang }) => {
  const [content, setContent] = useState<any>(undefined);
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const { hash } = useLocation();
  const { lien } = useParams<CmsPageParams>();

  const displayBlocRf = lien === 'regroupement-familial' && type === 'procedure';

  const articleStore = useSelector(getArticles);

  const {
    data: layoutCms,
    error: layoutCmsError,
    isLoading: isLayoutCmsLoading,
  } = useGetLayoutCmsPageQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: globalCms,
    error: globalCmsError = false,
    isLoading: isGlobalCmsLoading = true,
  } = useGetGlobalCmsQuery({
    qs: globalQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (store && Array.isArray(store) && (type === 'news' || type === 'procedure')) {
      setContent(store?.find((el: any) => el.id === lien));
    } else {
      setContent(store);
    }
  }, [store, lien, type]);

  useEffect(() => {
    if (element && hash === '#apprendreFrancais') {
      const event = new CustomEvent<number>('startScroll', {
        detail: element.offsetTop,
      });
      window.dispatchEvent(event);
    }
  }, [element, hash]);

  if (isGlobalCmsLoading || isLayoutCmsLoading) {
    return <Loader />;
  }

  if (globalCmsError || layoutCmsError || !layoutCms) {
    return <div>Erreur</div>;
  }

  return (
    <main className={`cms-page ${type}`}>
      <div id="cms-page" className="fr-container fr-mt-6w fr-mb-5w fr-mt-md-7w">
        <AppBreadcrumb />
        {(content?.title || content?.titre) && (
          <h1 className={`fr-mb-md-12v fr-mb-10v ${type === 'cookie' ? 'align-left' : ''}`}>
            {content.title || content.titre}
          </h1>
        )}
        {displayBlocRf && (
          <ClickableCard
            imageUrl="/img/dashboard/illustration-rf-desktop.svg"
            imageMobileUrl="/img/dashboard/illustration-rf-mobile.svg"
            imageAlt={globalCms.data.attributes.balise_alt_illustration_demarrage_procedure_rf}
            title={globalCms.data.attributes.titre_bloc_demarrage_regroupement_familial}
            desc={globalCms.data.attributes.contenu_bloc_demarrage_regroupement_familial}
            detail={globalCms.data.attributes.nom_bloc_demarrage_regroupement_familial}
            classes={{
              detail: 'fr-icon-arrow-right-line',
              root: 'fr-mb-3w',
            }}
            href="/tableau-de-bord/"
            badgeText={globalCms.data.attributes.nouveaute}
          />
        )}
        {content && content.description && <p className="description">{content.description}</p>}
        {content && content.image && type === 'news' && (
          <AppImage src={content.image.src} ariaHidden={false} className="max-width" />
        )}
        {content && content.contenu && (
          <CmsContentGenerator
            setElement={setElement}
            content={content.contenu}
            accIntegrationType={type === 'accueil_et_integration'}
          />
        )}
        {content && content.description_page && (
          <CmsContentGenerator
            setElement={setElement}
            content={[{ id: 0, __component: 'none', contenu: content.description_page }]}
          />
        )}
        {/* {type === 'rgpd' && <RgpdButton />} // Desactivated for DSFR Audit */}
      </div>
      {articleStore && (
        <FourCardContainer
          className="news"
          title={layoutCms.data.attributes.articles.titre}
          link={{
            href: ARTICLES,
            title: layoutCms.data.attributes.articles.bouton_voir_plus,
          }}
          cards={generateSection(articleStore.slice(0, 4))}
        />
      )}
    </main>
  );
};

export default CmsPage;
