import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Highlight } from '@codegouvfr/react-dsfr/Highlight';

import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import Loader from 'components/Atoms/Loader/Loader';
import { RFStatut } from 'models/types/api/rf';
import { useGetSuiviRfDocumentsQuery, useGetSuiviRfQuery } from 'redux/rtk/api/apiRf';
import { getCurrentLocale } from 'redux/locale/selectors';

import Timeline from './components/Timeline';
import Beneficiaires from './components/Beneficiaires';
import Documents from './components/Documents';
import Recap from './components/Recap';
import MiseEnAvant from './components/MiseEnAvant';
import StatutAvancement from './components/StatutAvancement';
import DetailsInstruction from './components/DetailsInstruction';
import { shouldDisplayEnquete } from './helpers/utils';
import { getCmsContent } from './helpers/cmsContent';
import './EspaceRF.scss';

// eslint-disable-next-line complexity
const EspaceRF: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const { numeroDossier } = useParams<{ numeroDossier: string }>();
  if (!numeroDossier)
    throw new Error('This component should not be rendered outside a route with an id param');
  const { suiviRFCms, traductionRFCms, isCmsLoading, isCmsOnError } = getCmsContent(lang);
  const { data: demande, isLoading, isError } = useGetSuiviRfQuery(numeroDossier);
  const {
    data: documents,
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
  } = useGetSuiviRfDocumentsQuery(numeroDossier);

  if (isLoading || isDocumentsLoading || isCmsLoading) return <Loader />;
  if (isError || isCmsOnError) <div>Erreur</div>;
  if (!demande) return <div>No data</div>;
  if (!suiviRFCms) return <div>No data</div>;

  return (
    <main id="espace-rf">
      <div className="fr-container fr-mb-md-7w fr-mb-6w">
        <AppBreadcrumb />
        <h1 className="fr-mt-3w fr-mt-md-5w fr-mb-5w">{suiviRFCms.titre_page}</h1>
        <Highlight
          size="lg"
          classes={{
            root: 'fr-highlight--hipe fr-mb-5w',
          }}
        >
          {suiviRFCms.numero_dossier_label} <span>{numeroDossier}</span>
        </Highlight>

        <h3>{suiviRFCms.sous_titre}</h3>
        {demande.statut !== RFStatut.DOSSIER_CLOTURE && (
          <>
            <StatutAvancement demande={demande} cmsTrad={traductionRFCms} />
            <Timeline demande={demande} cmsTrad={traductionRFCms} />
          </>
        )}
        <MiseEnAvant demande={demande} traductionRFCms={traductionRFCms} suiviRFCms={suiviRFCms} />
        {demande.responsableStatut && (
          <Highlight
            className="fr-mb-3w fr-mb-md-5w"
            classes={{
              root: 'fr-highlight--hipe ',
            }}
          >
            {suiviRFCms.organisation_en_charge} <span>{demande.responsableStatut}</span>
          </Highlight>
        )}

        {shouldDisplayEnquete(demande) && (
          <DetailsInstruction
            enqueteLogement={demande.enqueteLogement}
            enqueteRessource={demande.enqueteRessources}
            traductionRFCms={traductionRFCms}
            cmsDetailsInstruction={suiviRFCms.rf_details_instruction}
          />
        )}
        {demande.beneficiaires.length > 0 && (
          <Beneficiaires
            beneficiaires={demande.beneficiaires}
            cmsBeneficiaires={suiviRFCms.rf_beneficiaires}
          />
        )}
        <div className="fr-grid-row fr-grid-row--gutters fr-mt-5w">
          <div className="fr-col-12 fr-col-md-6">
            <Documents
              documents={documents ? documents : []}
              cmsDocuments={{
                rf_documents: suiviRFCms.rf_documents,
                documents_non_disponibles: suiviRFCms.documents_non_disponibles,
              }}
              isError={isDocumentsError}
            />
          </div>
          <div className="fr-col-12 fr-col-md-6 recap-demande">
            <Recap demande={demande} cmsRecap={suiviRFCms.rf_recapitulatif} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default EspaceRF;
