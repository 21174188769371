import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeycloakStatus } from 'services/authentication';

export type KeycloakState = Readonly<{
  keycloakStatus: KeycloakStatus;
}>;

const initialState: KeycloakState = {
  keycloakStatus: KeycloakStatus.UNKNOWN,
};

const keycloakSlice = createSlice({
  name: 'Keycloak',
  initialState,
  reducers: {
    setKeycloakStatus: (state, action: PayloadAction<KeycloakStatus>) => {
      state.keycloakStatus = action.payload;
    },
  },
});

export const { setKeycloakStatus } = keycloakSlice.actions;
export default keycloakSlice.reducer;
