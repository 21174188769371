import qs from 'qs';

const statutFields = {
  fields: [
    'statut',
    'explication_titre',
    'explication_description',
    'sous_statut',
    'en_attente_details',
  ],
};

export const traductionApiRfQuery1 = qs.stringify({
  populate: {
    fields: ['demande_rf_en_cours'],
    nom_etapes: {
      fields: ['receptionnee', 'en_cours_instruction', 'en_attente_decision', 'decision'],
    },
    statut_etapes: {
      fields: ['a_venir', 'en_cours', 'termine', 'en_attente_usager', 'refusee'],
    },
    lien_familial: {
      fields: ['enfant', 'epoux'],
    },
    statuts_enquetes: {
      fields: ['en_cours', 'terminee', 'sans_suite', 'dispensee'],
    },
    motifs_enquete_ressources: {
      fields: [
        'allocation_adultes_handicapes',
        'plus_65_ans',
        'allocation_supplementaire_invalidite',
      ],
    },
    demande_rf_etapes: {
      fields: ['a_completer', 'en_cours_de_traitement', 'decision_favorable'],
    },
  },
});
export const traductionApiRfQuery2 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        receptionne__receptionne_document_recu: statutFields,
        receptionne__receptionne: statutFields,
        receptionne__receptionne_attente_retour_prefecture: statutFields,
      },
    },
  },
});

export const traductionApiRfQuery3 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        receptionne__receptionne_attente_documents_complementaires: statutFields,
        en_cours_instruction__en_cours_instruction: statutFields,
        pas_etape__dossier_cloture__demande_interresse: statutFields,
      },
    },
  },
});

export const traductionApiRfQuery4 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        pas_etape__dossier_cloture__acquisition_nationnalite_francaise: statutFields,
        pas_etape__dossier_cloture__demenagement_departement: statutFields,
      },
    },
  },
});

export const traductionApiRfQuery5 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        pas_etape__dossier_cloture__dt_non_competente: statutFields,
        pas_etape__dossier_cloture__dossier_incomplet: statutFields,
        pas_etape__dossier_cloture: statutFields,
      },
    },
  },
});

export const traductionApiRfQuery6 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        pas_etape__dossier_cloture__dossier_irrecevable: statutFields,
        portail__en_cours_de_depot: statutFields,
        portail__receptionne: statutFields,
      },
    },
  },
});

export const traductionApiRfQuery7 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        attente_decision_prefecture__attente_decision_prefecture: statutFields,
        decision__decision_attente_retour_prefecture: statutFields,
        decision__favorable: statutFields,
      },
    },
  },
});

export const traductionApiRfQuery8 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        decision__favorable_attente_paiement: statutFields,
        decision__favorable_relance_paiement: statutFields,
        decision__defavorable: statutFields,
      },
    },
  },
});

/*export const traductionApiRfQuery7 = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {

      },
    },
  },
});*/

export const dashboardProceduresEnCoursQuery1 = qs.stringify({
  populate: {
    procedures_en_cours: {
      fields: ['titre', 'texte_aucune_procedure', 'titre_en_cours', 'titre_termines'],
      populate: {
        procedure_asile: {
          fields: [
            'titre',
            'description_acceptee',
            'description_refusee',
            'description_refus_de_signer',
            'texte_lien_espace_asile',
          ],
        },
        procedure_regroupement_familial: {
          fields: ['titre', 'numero_dossier', 'texte_lien_espace_regroupement_familial'],
        },
      },
    },
  },
});

export const dashboardProceduresEnCoursQuery2 = qs.stringify({
  populate: {
    procedures_en_cours: {
      populate: {
        procedure_retour: {
          fields: [
            'titre',
            'description_date_enregistrement',
            'description_avis_defavorable',
            'description_retour_effectue',
            'texte_lien_espace_retour',
          ],
        },
        procedure_reinsertion: {
          fields: [
            'titre_niveau_aide_1',
            'titre_niveau_aide_2_et_3',
            'description_niveau_aide_1',
            'description_niveau_aide_2',
            'description_niveau_aide_3',
            'label_etape_prise_de_rdv',
            'label_etape_etude_en_cours',
            'label_etape_decision',
            'texte_lien_espace_reinsertion',
          ],
        },
      },
    },
  },
});
