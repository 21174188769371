import { Conjoint, Demande, Enfant, Union } from 'models/types/api/rf';

import { apiSlice } from './apiSlice';
import { RFDemandeContenuApi, RFDemande as RFDemandeApi } from 'models/types/api/rf';
import { DocumentRF, DocumentsFromApi } from '../../../models/types/api/documents';
import { ProcedureType } from '../../../constants/procedure';
//import { API_DATE_FORMAT } from '../../../constants/time';
//import { deserializeNullableDate } from '../../../services/date';

interface RfDemande {
  id: string;
  body: any;
  isValidation: boolean;
  demandeId?: string | null;
  etape: string;
}

const apiRf = apiSlice.injectEndpoints({
  endpoints: build => ({
    getDemandeRfs: build.query<any, { demandeId?: string }>({
      query: ({ demandeId }) => ({
        url: `/api/private/regroupement-familial/preparation-demandes${
          demandeId ? `/${demandeId}` : ''
        }`,
      }),
    }),
    getDemandeRfCreation: build.query<{ demande_creation: boolean }, void>({
      query: () => ({
        url: `/api/private/regroupement-familial/preparation-demandes/creation`,
      }),
      keepUnusedDataFor: 0,
    }),
    getDemandeRfSummary: build.query<any, void>({
      query: () => ({
        url: `/api/private/regroupement-familial/liste-demande-summary`,
      }),
    }),
    getDemande: build.query<Demande, string>({
      query: demandeId => ({
        url: `/api/private/regroupement-familial/preparation-demandes/${demandeId}`,
      }),
    }),
    updDemande: build.mutation<Demande, Partial<RfDemande>>({
      query: data => {
        const validation = data.isValidation ? '/validation' : '';
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/etape/${data.etape}${validation}`,
          method: 'PATCH',
          body: data.body,
        };
      },
    }),
    submitDemande: build.mutation<Demande, Partial<RfDemande>>({
      query: data => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/soumettre`,
          method: 'PUT',
          body: data.body,
        };
      },
    }),
    newDemande: build.mutation<Partial<RfDemande>, undefined>({
      query: () => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes`,
          method: 'POST',
        };
      },
    }),
    getDemandeList: build.query<any, void>({
      query: () => ({
        url: `/api/private/regroupement-familial/preparation-demandes`,
      }),
    }),
    newConjoint: build.mutation<Conjoint, any>({
      query: data => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/conjoints`,
          method: 'POST',
          body: data.body,
        };
      },
    }),
    updConjoint: build.mutation<Conjoint, any>({
      query: data => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/conjoints/${data.body.id}`,
          method: 'PATCH',
          body: data.body,
        };
      },
    }),
    delConjoint: build.mutation({
      query: ({ demandeId, id }) => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${demandeId}/conjoints/${id}`,
          method: 'DELETE',
        };
      },
      //invalidatesTags: ["Demande"],
    }),
    newEnfant: build.mutation<Enfant, any>({
      query: data => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/enfants`,
          method: 'POST',
          body: data.body,
        };
      },
    }),
    updEnfant: build.mutation<Enfant, any>({
      query: data => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/enfants/${data.body.id}`,
          method: 'PATCH',
          body: data.body,
        };
      },
    }),
    delEnfant: build.mutation({
      query: ({ demandeId, id }) => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${demandeId}/enfants/${id}`,
          method: 'DELETE',
        };
      },
      //invalidatesTags: ["Demande"],
    }),
    newUnion: build.mutation<Union, any>({
      query: data => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/unions`,
          method: 'POST',
          body: data.body,
        };
      },
    }),
    updUnion: build.mutation<Union, any>({
      query: data => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${data.demandeId}/unions/${data.body.id}`,
          method: 'PATCH',
          body: data.body,
        };
      },
    }),
    delUnion: build.mutation({
      query: ({ demandeId, id }) => {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${demandeId}/unions/${id}`,
          method: 'DELETE',
        };
      },
      //invalidatesTags: ["Demande"],
    }),
    getSuiviRf: build.query<RFDemandeApi, string>({
      query: dossierId => ({
        url: `/api/private/regroupement-familial/demandes/${dossierId}`,
      }),
      transformResponse: (response: RFDemandeContenuApi) => response.contenu,
    }),
    getSuiviRfDocuments: build.query<DocumentRF[], string>({
      query: dossierId => ({
        url: `/api/private/documents-rf?numeroDossier=${dossierId}`,
      }),
      transformResponse: (documentsFromApi: DocumentsFromApi) => {
        return documentsFromApi.contenu.map(documentFromApi => ({
          ...documentFromApi,
          procedure: ProcedureType.REGROUPEMENT_FAMILIAL,
          numeroDossier: '',
        })) as DocumentRF[];
      },
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetDemandeRfsQuery,
  useGetDemandeRfSummaryQuery,
  useGetDemandeListQuery,
  useUpdDemandeMutation,
  useGetDemandeRfCreationQuery,
  useGetDemandeQuery,
  useNewDemandeMutation,
  useSubmitDemandeMutation,
  useNewConjointMutation,
  useUpdConjointMutation,
  useDelConjointMutation,
  useNewEnfantMutation,
  useUpdEnfantMutation,
  useDelEnfantMutation,
  useNewUnionMutation,
  useUpdUnionMutation,
  useDelUnionMutation,
  useGetSuiviRfQuery,
  useGetSuiviRfDocumentsQuery,
} = apiRf;
