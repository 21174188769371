import { CountryReferentialItem } from 'models/types/api/entities';
import { LocationSearchResponse } from 'models/types/cms/location/types';

import { apiSlice } from './apiSlice';

const apiCommon = apiSlice.injectEndpoints({
  endpoints: build => ({
    getUsagerListePays: build.query<CountryReferentialItem[], void>({
      query: () => ({ url: '/api/usager/pays' }),
    }),
    getUsagerListeNationalites: build.query<CountryReferentialItem[], void>({
      query: () => ({ url: '/api/usager/nationalites' }),
    }),
    getNationalitesNonEligibles: build.query<CountryReferentialItem[], void>({
      query: () => ({ url: '/api/demande/nationalites-non-eligibles' }),
    }),
    getDirectionTerritorialesAnonymous: build.query<LocationSearchResponse, string>({
      query: codePostal => ({ url: `/api/direction-territoriale?codePostal=${codePostal}` }),
    }),
    getDirectionTerritorialesAuthenticated: build.query<LocationSearchResponse, void>({
      query: () => ({ url: '/api/private/direction-territoriale' }),
    }),
    newContact: build.mutation({
      query: body => ({
        url: `/api/contact`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useGetUsagerListePaysQuery,
  useGetUsagerListeNationalitesQuery,
  useGetNationalitesNonEligiblesQuery,
  useGetDirectionTerritorialesAnonymousQuery,
  useGetDirectionTerritorialesAuthenticatedQuery,
  useNewContactMutation,
} = apiCommon;
